<template>
  <!-- 个人中心的任务详情 -->
  <div class="tasklist-bidder">
    <div class="tasklist-bidder-top">
      <div class="person-header">
        <p>
          <strong>任务详情</strong>
        </p>
      </div>
      <div class="person-bottom">
        <div class="person-bottom-header flex-sb">
          <div class="person-bottom-header-right">
            <span class="h-57 f-12">项目编号：{{ task.sn }}</span>
          </div>
        </div>
        <div class="person-bottom-center">
          <p>{{ task.subject }}</p>
          <div class="person-bottom-center-font" v-show="showContent">
            <p v-show="show">
              {{ task.content }}
              <button @click="showButt">展开</button>
            </p>
            <p v-show="hide">{{ task.content }}
              <button @click="hideButt">收起</button>
            </p>
            <ul>
              <li>
                <el-button type="text">
                  <i class="iconfont icon-pay wh-16 b"></i>
                  预算{{ task.budget_amount }}
                </el-button>
              </li>
              <li>
                <el-button type="text">
                  <i class="iconfont icon-website b wh-16"></i>
                  {{ task.pay_channel_name }}
                </el-button>
              </li>

              <!--固定薪酬-->
              <li v-if="task.pay_way == 1">
                <el-button type="text">
                  <i class="iconfont icon-definite b wh-16"></i>
                  {{ task.pay_way_name }}
                </el-button>
              </li>
              <!--时薪-->
              <li v-if="task.pay_way == 2">
                <el-button type="text">
                  <i class="iconfont icon-hour b wh-16"></i>
                  {{ task.pay_way_name }}
                </el-button>
              </li>

              <!--支付周期 周、月、一次性、分期-->
              <li v-if="task.pay_cycle == 1">
                <el-button type="text">
                  <i class="iconfont icon-week b wh-16"></i>
                  {{ task.pay_cycle_name }}
                </el-button>
              </li>
              <li v-if="task.pay_cycle == 2">
                <el-button type="text">
                  <i class="iconfont icon-month b wh-16"></i>
                  {{ task.pay_cycle_name }}
                </el-button>
              </li>
              <li v-if="task.pay_cycle == 3">
                <el-button type="text">
                  <i class="iconfont icon-whole b wh-16"></i>
                  {{ task.pay_cycle_name }}
                </el-button>
              </li>
              <li v-if="task.pay_cycle == 4">
                <el-button type="text">
                  <i class="iconfont icon-installment b wh-16"></i>
                  {{ task.pay_cycle_name }}
                </el-button>
              </li>
              <li>
                <el-button type="text">
                  <i class="iconfont icon-personal b wh-16"></i>
                  雇佣 {{ task.plan_hire_count }} 人
                </el-button>
              </li>
              <li v-if="task.work_location == 2">
                <el-button type="text">
                  <i class="iconfont icon-address b wh-16"></i>
                  工作地点 {{ task.work_region_name }}
                </el-button>
              </li>
            </ul>

            <ol>
              <strong>所需技能：</strong>
              <li v-for="(item,key) in task.skills" :key="key">{{ item.name }}</li>
            </ol>
            <div class="person-bottom-img flex" v-if="attrCount>0">
              <div class="person-bottom-img-left">
                <strong class="f-14">相关附件：</strong>
              </div>
              <div class="person-bottom-img-right">
                <dl>
                  <li v-for="(attr,index) in task.attrs" :key="index">
                    <i class="iconfont icon-file-DOC wh-12 b"></i>
                    <span>{{ attr.file_name }}</span>
                  </li>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tasklist-bidder-bottom" ref="svgtr">
      <svg class="icon arrow-large-down" :class="{down:isActive,up:hasError}" aria-hidden="true" @click="changeShow">
        <use xlink:href="#icon-arrow-large-down"></use>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoHeader",
  data() {
    return {
      isShow: true, //显示展开与收起
      showContent: false, //显示中间
      show: true, //显示
      hide: false, //隐藏
      isActive: true,
      hasError: false,
      attrCount: 0
    };
  },
  props: {
    task: {
      type: Object,
      default: () => {
      }
    },

  },
  computed: {
    attrCount: function () {
      return this.task.attrs.length;
    }
  },
  methods: {
    handleCommand(command) {
      this.$message("click on item " + command);
    },
    changeShow() {
      this.isShow = !this.isShow;
      this.showContent = !this.showContent;
      this.hasError = !this.hasError;
    },
    showButt() {
      this.show = false;
      this.hide = true;
    },
    hideButt() {
      this.show = true;
      this.hide = false;
    },
  },
  computed: {
    showOrHide() {
      return this.isShow ? "展开" : "收起";
    },
  },
};
</script>

<style lang="less" scoped>
.tasklist-bidder {
  background: #fff;

  .tasklist-bidder-top {
    width: 940px;

    .person-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #b5b5b5;

      p {
        height: 18px;
        display: flex;
        align-items: center;

        img {
          margin-left: 20px;
        }

        big {
          margin-left: 10px;
        }

        span {
          margin-left: 20px;
        }
      }

      /deep/ .el-dropdown {
        margin-top: 40px;
        margin-right: 40px;
      }
    }

    .person-bottom {
      width: 860px;
      margin: 0 auto;

      .person-bottom-header {
        margin-top: 40px;

        .person-bottom-header-left {
          span {
            font-size: 16px;
          }
        }
      }

      .person-bottom-center {
        margin-top: 20px;

        p {
          font-size: 18px;
          width: 840px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .person-bottom-center-font {
          p {
            font-size: 12px;
            color: #575757;
            margin-top: 20px;

            button {
              background-color: transparent;
              color: #00a2e6;
            }
          }

          ul {
            display: flex;
            flex-wrap: wrap;
            height: 42px;
            align-items: center;
            margin-top: 20px;
            text-align: center;

            li {
              height: 16px;
              margin-bottom: 10px;

              /deep/ .el-button {
                height: 16px;
                padding: 0;
              }

              /deep/ .el-button--text {
                color: #575757;
                margin-left: 10px;
                text-align: center;
              }
            }
          }

          ol {
            height: 24px;
            margin-top: 20px;
            display: flex;
            align-items: center;

            al strong {
              height: 24px;
              font-size: 14px;
              text-align: center;
            }

            li {
              color: #fff;
              background-color: #00a2e6;
              padding: 7px 13px;
              border-radius: 12px;
              margin-left: 10px;
              font-size: 12px;
            }
          }

          .person-bottom-img {
            margin-top: 20px;

            dl {
              li {
                margin-bottom: 10px;

                span {
                  margin-left: 10px;
                  font-size: 12px;
                  color: #575757;
                }
              }
            }
          }
        }
      }
    }
  }

  .tasklist-bidder-bottom {
    width: 160px;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 20px;

    button {
      width: 160px;
      height: 24px;
      background-color: #00a2e6;
    }
  }
}

/deep/ .el-dropdown-link {
  cursor: pointer;
  color: #00a2e6;
}

.arrow-large-down {
  width: 60px;
  height: 10px;
  cursor: pointer;
}

.down {

}

.up {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
</style>