<template>
  <div v-loading="mainLoading">
    <taskInfolist :task="task" class="taskinfo"></taskInfolist>

    <!--  评论板块 start   -->
    <div class="task-info-review" v-if="task.status == 9" style="margin-top:20px;">
      <div class="person-header">
        <p>
          <strong>
            评价
          </strong>
          <span class="ml-15">请对任务发布方作出评价，您的评价可以帮助平台更好的提升体验。</span>
        </p>
      </div>
      <!--只有一个自由职业者 start-->
      <div class="task-info-review-first" v-loading="showEvaluateLoading">
        <div class="task-info-review-first-center">
          <el-form>
            <el-form-item label="评分：" prop="startValue" style="margin-bottom: 0;">
              <el-rate
                  v-model="singleEvaluate.score"
                  :disabled="singleEvaluate.id > 0"
                  show-text
                  :texts="texts">
              </el-rate>
            </el-form-item>
            <el-form-item label="评论：" prop="reviewFont">
              <el-input
                  v-show="singleEvaluate.id == 0"
                  type="textarea"
                  v-model="singleEvaluate.content"
                  placeholder="请填写评价内容，最多可输入500个字符。"
                  style="width: 860px"
                  show-word-limit
                  maxlength="500"
                  :autosize="{ minRows: 6, maxRows: 10}"
              ></el-input>
              <div class="task-info-review-first-success" v-show="singleEvaluate.id > 0 ">
                <br/>
                <p class="f-12 h-57">
                  {{singleEvaluate.content}}
                </p>
                <div class="task-info-review-first-success-font" style="text-align: center">
                  <i class="iconfont icon-success g wh-16"></i><span class="h-8d ml-10">评价成功，感谢您的评价。</span>
                </div>
              </div>
              <div class="task-info-review-first-button" v-show="singleEvaluate.id == 0" style="display: flex;justify-content: flex-end;margin-top:10px;">
                <p class="f-12 r" style="line-height:1rem;padding:7px 20px 7px 0">{{evaluateErrorMsg}}</p>
                <el-button type="primary" style="width:120px;border-radius: 0" size="mini" @click="submitEvalute">提交评论</el-button>
              </div>
            </el-form-item>

          </el-form>
        </div>
      </div>
    </div>
      <!--只有一个自由职业者  end-->


    <div class="project-administration">
      <div class="person-header">
        <p>
          <strong>任务周期</strong>
        </p>
      </div>
      <div class="mb-40"></div>
      <div class="project-admin-bottom">
        <div class="swiper-container" ref="floor1Swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(period, index) in periods" @click="loadPeriodDetail(period)" :key="index">
              <div class="project-admin-bottom-box1"
                   :class="{ hoverBlue: period.is_current == 1 }">
                <div class="project-admin-bottom-box1-header">
                  <div class="mb-20"></div>
                  <span>第{{ period.number }}{{ task.period_unit }}</span>
                  <div class="mb-10"></div>
                  <div class="project-admin-bottom-box1-header-img">
                    <i class="mr-10 iconfont icon-wait h-57 wh-16"></i>
                    <strong class="f-24 h-57">{{ period.show_info_freelancer.title }}</strong>
                    <div class="mb-20"></div>
                  </div>
                </div>
                <div class="project-admin-bottom-box1-footer">
                  <div class="mb-10"></div>
                  <p class="f-12 h-57">{{ period.show_info_freelancer.sub }}</p>
                  <div class="mb-10"></div>
                  <p class="f-12 h-57">{{ period.show_info_freelancer.desc }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>

      <!--单个周期主体区域 start-->
      <div v-loading="periodDetailBoxLoading">

        <div class="check-status" v-if="currentWorkItem.is_kickout == 1">
          <p>
            <i class="iconfont icon-tips r ml-20"></i><strong class="f-18 r ml-10 mr-20">您无法参与本周期工作</strong>
            <span class="f-12">雇主已经暂停了您本周期的工作，若有疑问，请及时与雇主取得联系</span>
          </p>
        </div>
        <!--！！！工作暂停！！！-->
        <div class="check-status" v-else-if="currentPeriod.status == 3">
          <p>
            <i class="iconfont icon-tips r ml-20"></i><strong class="f-18 r ml-10 mr-20">请暂停工作！！</strong>
            <span class="f-12">为了保障您的权益，请在雇主委托平台托管资金后再继续您的工作</span>
          </p>
        </div>
        <!--有纠纷-->
        <div class="check-status" v-else-if="currentWorkItem.has_judgment == 1">
          <p>
            <i class="iconfont icon-tips r ml-20"></i><strong class="f-18 r ml-10 mr-20">仲裁中</strong>
            <span class="f-12">已经提交平台仲裁，请耐心等待</span>
          </p>
        </div>
        <!--  验收时的状态-->
        <div class="check-status" v-else>
          <!--工作未开始-->
          <p v-show="currentWorkItem.status == 1">
            <i class="iconfont icon-wait b ml-20"></i><strong class="f-18 b ml-10 mr-20">工作未开始</strong>
            <span class="f-12" v-if="task.pay_way == 1">请与雇主保持联系，或关注提示短信或站内信</span>
            <span class="f-12"
                  v-if="task.pay_way == 2">将于{{ currentPeriod.work_start_time }}开始您的工作</span>
          </p>

          <!--工作中-->
          <p v-show="currentWorkItem.status == 3">
            <i class="iconfont icon-wait b ml-20"></i><strong class="f-18 b ml-10 mr-20">工作中...</strong>
            <span class="f-12" v-if="task.pay_way == 1">请于雇主保持密切沟通，此期间内您可以提交工作成果，在验收之前可重复多次提交！</span>
            <span class="f-12"
                  v-if="task.pay_way == 2">本期工作将于{{ currentPeriod.work_end_time }}结束，请关注系统提醒</span>
          </p>

          <!--已提交-->
          <p v-show="currentWorkItem.status == 4">
            <i class="iconfont icon-loading b ml-20"></i><strong class="f-18 b ml-10 mr-20">等待验收</strong>
            <span class="f-12">您与{{ currentWorkItem.submit_check_time }}提交了工作成果，雇主验收前您可以重复提交</span>
          </p>
          <!--验收失败-->
          <p v-show="currentWorkItem.status == 5">
            <i class="iconfont icon-fail r ml-20"></i><strong class="f-18 r ml-10 mr-20">未通过验收</strong>
            <span class="f-12">未通过原因：{{ currentWorkItem.last_check_memo }}</span>
          </p>
          <!--验收通过 且无资金变动-->
          <p v-show="currentWorkItem.status == 6 && !currentWorkItem.has_salary_change ">
            <i class="iconfont icon-success g ml-20"></i><strong class="f-18 g ml-10 mr-20">验收通过</strong>
            <span class="f-12">您的工作成果已验收通过，项目款将于{{currentWorkItem.plan_grant_time}}发放到您账户。</span>
          </p>
          <!--验收通过 ！！有资金变动！！-->
          <p v-show="currentWorkItem.status == 6 && currentWorkItem.has_salary_change ">
            <i class="iconfont icon-tips r ml-20"></i><strong class="f-18 r ml-10 mr-20">验收通过(金额有异动)</strong>
            <span class="f-12">您的工作成果已验收通过，雇主将您的薪资由<b class="r f-16">¥{{currentWorkItem.plan_salary_amount_yuan}}</b>调整为<b class="r f-16">¥{{currentWorkItem.real_salary_amount_yuan}}</b></span>
          </p>

          <!--已付款-->
          <p v-show="currentWorkItem.status == 9">
            <i class="iconfont icon-pay g ml-20"></i><strong class="f-18 g ml-10 mr-20">薪资已发放</strong>
            <span class="f-12">本期薪资已于{{ currentWorkItem.real_grant_time }}划拨至您的账户，请前往查看</span>
          </p>
        </div>
        <div class="mb-20"></div>
        <div v-if="currentWorkItem.is_kickout == 0">
          <!-- 周期工时明细 start-->
          <div class="period-hour" v-if="task.pay_way == 2 ">
            <p>
              <strong class="f-18">周期工时明细</strong>
              <i v-show="canEdit" class="iconfont icon-tips b wh-16 ml-20 mr-10"></i>
              <span v-show="canEdit" class="h-57 f-12">工时明细为非必填项，请遵从与雇主的约定，否则会影响验收效果。</span>
            </p>
            <div class="mb-20"></div>
            <div class="project-administration-bottom-employee">
              <div class="project-administration-bottom-employee-tabel">
                <el-table
                    class="el-tabel"
                    ref="multipleTable"
                    :data="currentWorkItem.details"
                    style="width: 860px; font-size: 12px"
                    border
                    :header-cell-style="{
                    'text-align': 'center',
                    height: '32px',
                    color: '#000',
                    'background-color':'#f4f4f4',
                  }"
                    :cell-style="{
                    height: '36px',
                    color: '#474747',
                  }"
                >
                  <el-table-column label="日期" width="100" prop="date_day" align="center"></el-table-column>
                  <el-table-column prop="week_day" label="星期" width="70" align="center">
                  </el-table-column>
                  <el-table-column label="工作时长" width="89px" align="left">
                    <editable-cell v-if="canEdit" max="24" min="0" tool-tip-content="点击编辑当日工作时长（小时）"
                                   :show-input="row.editMode"
                                   slot-scope="{row}" v-model="row.effective_hourly">
                      <span slot="content">{{ row.effective_hourly }}</span>
                    </editable-cell>
                    <template v-else slot-scope="{row}">
                      {{ row.effective_hourly }}
                    </template>
                  </el-table-column>
                  <el-table-column label="工作概述" width="340px" align="left">
                    <editable-cell v-if="canEdit" style="width:100%;" max-length="255" tool-tip-content="点击编辑当日工作概述"
                                   :show-input="row.editMode"
                                   slot-scope="{row}" v-model="row.work_memo">
                      <span slot="content">{{ row.work_memo != '' ? row.work_memo : '点击编辑工作描述' }}</span>
                    </editable-cell>
                    <template v-else slot-scope="{row}">
                      {{ row.work_memo }}
                    </template>
                  </el-table-column>
                  <el-table-column label="凭证" width="159px" align="left">
                    <template slot-scope="scope">
                      <div class="proofImg" style="display:flex;flex-direction: row;flex-wrap:wrap;width:100%;">
                        <div v-for="(file,index) in scope.row.work_files" :key="index"
                             style="padding:0 5px;cursor: pointer">
                          <el-tooltip placement="top">
                            <div slot="content">{{ file.name }}
                              <hr/>
                              可点击图标
                            </div>
                            <i :class="getFileIcon(file.icon)" @click="openWorkFileAction(file, scope.$index )"></i>
                          </el-tooltip>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="91px" align="center">
                    <template slot-scope="scope">
                      <el-upload
                          style="width: 30px;height:20px;"
                          v-show="canEdit"
                          ref="uploadAttr"
                          :action="postUrl"
                          :data="{idx:scope.$index,file_type:'all'}"
                          :headers="headers"
                          :show-file-list="false"
                          :before-upload="beforeAvatarUpload"
                          :on-success="onUploadWorkDetailAttr"
                          multiple
                      >
                        <el-button style="width: 30px;" type="text">上传</el-button>
                      </el-upload>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <div class="mb-40"></div>
          </div>
          <!-- 周期工时明细 end -->
          <!-- 交付文件清单 start-->
          <div class="taskList-file">
            <p>
              <strong class="f-18">交付文件清单</strong>
            </p>
            <div class="mb-20"></div>
            <el-upload
                v-if="canEdit"
                ref="uploadAttr"
                class="upload-demo"
                drag
                :action="postUrl"
                :data="{ file_type: 'all' }"
                :headers="headers"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="uploadAttrSuccess"
                multiple
            >
              <div class="upload-center">
                <img src="@/icons/svg/task/添加图片16X16.svg" class="mr-10"/>
                <span class="el-upload__text">拖拽或点击此处添加文件（文件总大小不超过25MB）</span>
              </div>
            </el-upload>

            <!-- 文件上传总数 -->
            <div class="task-accessory1-all">
              <div class="mb-10"></div>
              <p>共上传个{{ allFile ? allFile : '--' }}文件，共计{{ allFileSize ? allFileSize : '--' }}M</p>
            </div>
            <div class="mb-10"></div>
            <div class="project-administration-bottom-employee">
              <div class="project-administration-bottom-employee-tabel">
                <el-table
                    class="el-tabel"
                    ref="multipleTable"
                    :data="currentWorkItem.work_files"
                    style="width: 860px; font-size: 12px"
                    border
                    :header-cell-style="{
                    'text-align': 'center',
                    height: '32px',
                    color: '#000',
                    'background-color':'#f4f4f4',
                  }"
                    :cell-style="{
                    height: '36px',
                    color: '#474747',
                  }"
                >
                  <el-table-column label="序号" width="80" prop="name" align="center">
                    <template slot-scope="scope">
                      <span> 附件{{ (scope.$index + 1) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="文件" width="399px" align="left">
                    <template slot-scope="{row}">
                      <p><i :class="'b iconfont '+row.icon"></i><span class="h-57 ml-10">{{ row.name }}</span></p>
                    </template>
                  </el-table-column>
                  <el-table-column label="文件大小" width="119px" align="center">
                    <template slot-scope="{row}">
                      <span>{{ parseInt(row.size / 1024) }}KB</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="上传时间" width="158px" align="left" prop="created_at">
                  </el-table-column>
                  <el-table-column label="操作" width="102px" align="center">
                    <template slot-scope="scope">
                      <span v-show="canEdit" style="margin:0 4px;cursor: pointer"
                            @click="removeAttr(scope.$index)">删除</span>
                      <span style="margin:0 4px;cursor: pointer" @click="downloadAttr(scope.row)">下载</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <!--交付文件说明 start-->
            <div class="task-file-explain">
              <div class="mb-40"></div>
              <p style=""><span class="r">*</span>交付说明</p>
              <el-input v-model="currentWorkItem.work_memo" :disabled="!canEdit" type="textarea"
                        placeholder="请填写说明备注"></el-input>
            </div>
            <!--交付文件说明 end-->

            <div class="mb-40"></div>
            <button v-if="canEdit" type="button" @click="submitToCheck">提交验收</button>
            <button v-else type="button" class="errButton" v-show="false"></button>
            <div class="mb-40"></div>
          </div>
          <!-- 交付文件清单 end -->
        </div>
      </div>
      <!--单个周期主体区域 end-->

    </div>

    <el-dialog
        title="提示"
        :visible.sync="submitResultDialogVisible"
        width="30%">
      <span>您的工作成果已经提交成功，请等待雇主验收。</span>
      <span slot="footer" class="dialog-footer">
        <button type="primary" size="mini" @click="submitResultDialogVisible = false">确 定</button>
      </span>
    </el-dialog>

    <el-dialog
        title="文件操作"
        :visible.sync="fileHandDialogShow"
        width="50%"
        center>
      <span>{{ currentWorkDetailFile.name }}</span>
      <span slot="footer" class="dialog-footer">
      <button v-show="canEdit" @click="removeWorkDetailFile()">删除</button>
        <br/>
    <button type="primary" @click="downloadWorkDetailFile()">下载</button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import Swiper from "swiper";
import taskInfolist from "@/views/layout/modules/freelancer/tasklistInfo";
import EditableCell from "@/components/EditableCell.vue";

import {
  evaluateToTask,
  getProtectedInfo,
  loadWorkItemByFreelancer,
  submitWorkItem
} from '@/api/taskuc.js';

export default {
  components: {
    taskInfolist,
    EditableCell
  },
  mounted() {
    new Swiper(this.$refs.floor1Swiper, {
      pagination: ".swiper-pagination",
      slidesPerView: 4,
      paginationClickable: true,
      spaceBetween: 20,
      freeMode: true,
      grabCursor: true,
    });
  },
  data() {
    return {
      mainLoading: false,
      periodDetailBoxLoading: false,
      submitResultDialogVisible:false,
      taskSN: '',
      currentPeriod: {
        id: 0,
        is_current: 0
      },
      currentWorkItem: {
        details: [],
        work_files: [],
        allow_submit_check: false,
      },
      task: {},
      periods: {},
      fileHandDialogShow: false,
      currentWorkDetailFile: {},
      currentWorkDetailRowIdx: 0,

      deleteDialogVisible: false,
      addDialogVisible: false,
      postUrl: '/api/upload',
      headers: {Authorization: `Bearer ${this.$store.getters["useUserStore/getToken"]}`},

      texts: [
        "很差",
        "不满意",
        "一般",
        "还不错",
        "十分满意",
      ],
      showEvaluateLoading:false,
      singleEvaluate: {
        id:0,
        score: 0,
        content: ''
      },
      evaluateErrorMsg:'',
    };
  },
  created() {
    this.currentPeriod.id = this.$route.query.period_id || 0;
    this.taskSN = this.$route.query.sn;
    this.loadData();
  },
  methods: {
    async loadData() {
      this.mainLoading = true
      let _this = this
      await getProtectedInfo({sn: this.taskSN, need_period: 1, period_id: this.currentPeriod.id}).then(res => {
        if (res.statusCode == 200) {
          _this.task = res.data
          _this.periods = res.data.periods
          _this.currentPeriod = _this.periods.filter(function (item) {
            return item.is_current == 1;
          })[0]

          if( _this.task.evaluate_to_task ){
            _this.singleEvaluate = _this.task.evaluate_to_task
          }

          this.loadPeriodDetail(_this.currentPeriod)
        }
        setTimeout(function () {
          _this.mainLoading = false
        }, 500)
      }).catch();
    },
    loadPeriodDetail(period) {
      this.periodDetailBoxLoading = true
      let _this = this
      loadWorkItemByFreelancer({period_id: period.id}).then(res => {
        if (res.statusCode == 200) {
          this.currentPeriod = period
          this.currentWorkItem = res.data
          //当前period在列表中的数组下标
          let curIdx = this.periods.findIndex((item) => item.id == this.currentPeriod.id)
          let _this = this;
          this.periods.forEach(function (item, idx) {
            if (idx == curIdx) {
              _this.$set(_this.periods[idx], 'is_current', 1);
            } else {
              _this.$set(_this.periods[idx], 'is_current', 0);
            }
          });

          this.currentWorkItem.details = this.currentWorkItem.details.map(row => {
            return {
              ...row,
              editMode: false
            };
          });

        }
        setTimeout(function () {
          _this.periodDetailBoxLoading = false
        }, 1500)
      }).catch();
    },
    onUploadWorkDetailAttr(res, file) { //当完成单日工作凭证
      let _this = this;
      if (res.statusCode == 200) {
        res.data.forEach(function (item) {
          let idx = item.idx
          _this.currentWorkItem.details[idx].work_files.push(item);
        });
      } else {
        this.$message.error(res.message);
      }
    },
    getFileIcon(icon) {
      return 'iconfont ' + icon + ' b f-18';
    },
    openWorkFileAction(file, idx) {
      this.currentWorkDetailRowIdx = idx
      this.currentWorkDetailFile = file
      this.fileHandDialogShow = true;
    },
    removeWorkDetailFile() {
      let _this = this;
      let idx = this.currentWorkItem.details[this.currentWorkDetailRowIdx].work_files.findIndex(function (item) {
        return item.fileid == _this.currentWorkDetailFile.fileid;
      });

      this.currentWorkItem.details[this.currentWorkDetailRowIdx].work_files.splice(idx, 1);

      this.fileHandDialogShow = false;
    },
    downloadWorkDetailFile() {
      this.fileHandDialogShow = false;
      window.open(this.currentWorkDetailFile.url, '_blank')
    },
    submitToCheck() {
      let _this = this;
      console.log(this.currentWorkItem.work_memo)
      if (this.currentWorkItem.work_memo == '') {
        this.$message.error('请填写交付说明');
        return false;
      }

      //todo 交付说明不能大于 500个字
      //组装附件ID
      let workFileIds = []
      this.currentWorkItem.work_files.forEach(function (item) {
        workFileIds.push(item.fileid)
      })

      //拼接工时明细
      this.currentWorkItem.details.forEach(function (item, index) {
        item.work_files.forEach(function (file) {
          console.log(_this.currentWorkItem.details[index])
          _this.currentWorkItem.details[index].work_file_ids.push(file.fileid);
        });
      })

      this.periodDetailBoxLoading = true
      submitWorkItem({
        work_item_id: this.currentWorkItem.id,
        work_file_ids: workFileIds,
        memo: this.currentWorkItem.work_memo,
        details: this.currentWorkItem.details
      }).then(res => {
        if (res.statusCode === 200) {
          this.submitResultDialogVisible = true;
          this.loadData();
        } else {
          this.$message.error(res.message)
        }
        this.periodDetailBoxLoading = false
      }).catch()
    },
    submitEvalute(){
      this.evaluateErrorMsg = '';
      if( this.singleEvaluate.score == 0 ){
        this.evaluateErrorMsg = '请滑动⭐设置一个分值';
        return false;
      }

      if( this.singleEvaluate.content == '' ){
        this.evaluateErrorMsg = '请输入评价内容';
        return false;
      }

      this.showEvaluateLoading = true;
      evaluateToTask({
        task_id:this.task.id,
        score: this.singleEvaluate.score,
        content:this.singleEvaluate.content
      }).then(res=>{
        this.showEvaluateLoading = false;
        if( res.statusCode == 200 ){
          this.singleEvaluate.id = 1
        }else{
          this.evaluateErrorMsg = res.message
        }
      });
    },
    work() {
      this.checktab = true;
    },
    uploadAttrSuccess(res, file) {
      this.currentWorkItem.work_files.push(res.data[0])
    },
    removeAttr(index) {
      this.currentWorkItem.work_files.splice(index, 1)
    },
    downloadAttr(file) {
      window.open(file.url, '_blank')
    },
    // 在文件上传之前限制文件的大小
    beforeAvatarUpload(file) {
      this.fileSize = parseInt(file.size / 1024);
      let fileName = file.name;
      let pos = fileName.lastIndexOf('.');
      let lastName = fileName.substring(pos, fileName.length);
      // 可以上传的类型
      let fileExts = ['.jpg', '.jpeg', '.gif', '.psd', '.png', '.txt', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.zip', '.rar', 'ppt', 'pptx'];
      if (!this.$store.getters.inArray(lastName.toLowerCase(), fileExts)) {
        this.$message.error('文件必须为.jpg .png .pdf .doc .docx .zip .rar .xls .xlsx,ppt,pptx类型');
        return false;
      }
      const clt = file.size / 1024 < 500;
      this.isLt25M = parseInt(this.isLt25M) + parseInt(clt);

      if ((this.allFileSize + this.isLt25M) > 25) {
        this.$message.error('上传的附件总大小不可以超过 25M');
        return false;
      }
    },
  },
  computed: {
    canEdit() {
      return this.currentWorkItem.allow_submit_check;
    },
    // 总文件数
    allFile() {
      return this.currentWorkItem.work_files.length;
    },
    // 文件总大小
    allFileSize() {
      let sum = 0
      this.currentWorkItem.work_files.map((item => {
        sum += item.size
      }))
      let x = sum / 1024 / 1024;
      return x.toFixed(2);
    }
  }
};
</script>

<style lang="less" scoped>
.swiper-slide {
  width: auto;
  cursor: pointer;
}

.el-tabel {
  width: 860px;
  margin: 0 auto;

  /deep/ .el-input__inner {
    height: 24px;
    padding: 0;
    width: 40px;
    text-indent: 10px;
  }

  /deep/ .el-input {
    display: inline;

  }

}

.hands {
  cursor: pointer;
}


.project-admin-bottom-box1 {
  width: 200px;
  height: 160px;
  background-color: #f4f4f4;
  border-radius: 10px;
  margin-right: 20px;
  position: relative;

  .project-admin-bottom-box1-header,
  .project-admin-bottom-box1-footer {
    width: 160px;
    margin: 0 auto;
  }

  .project-admin-bottom-box1-header {
    border-bottom: 1px solid #b5b5b5;
  }

  .project-admin-bottom-box1-header-img,
  .project-admin-bottom-box1-footer {
    text-align: center;
  }

  .project-admin-bottom-box1-center1 {
    text-align: center;
    width: 58px;
    height: 65px;
    margin: 0 auto;
    cursor: pointer;

    .icon-a-addto {
      display: inline-block;
      margin-top: 48px;
      font-size: 32px;
      color: #00a2e6;
    }

    span {
      font-size: 14px;
    }

    b {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #00a2e6;
      background-color: rgba(244, 244, 244, 0.5);
    }
  }

  .project-admin-bottom-box1-err {
    position: absolute;
    top: 10px;
    right: 10px;
    color: red;
    opacity: 0.5;
  }
}

.project-administration {
  margin-top: 20px;
  background-color: #fff;

  .project-admin-bottom {
    width: 860px;
    margin: 0 auto;
    padding-bottom: 20px;
    border-bottom: 1px solid #b5b5b5;

    .project-admin-bottom-box {
      width: 860px;
      height: 160px;
      border: 1px solid black;
      display: flex;

      .project-admin-bottom-box-center {
        width: 880px;
        display: flex;
        justify-content: space-between;

        .project-admin-bottom-box1-left,
        .project-admin-bottom-box1-right {
          width: 90px;
          height: 160px;
          background: rgba(244, 244, 244, 0.8);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon-arrow-large-Left,
          .icon-arrow-large-right {
            display: block;
            font-size: 48px;
          }
        }
      }

      .project-admin-bottom-box1-center {
        display: flex;
      }

      .project-admin-bottom-box1 {
        width: 200px;
        height: 160px;
        background-color: #f4f4f4;
        border-radius: 10px;
        margin-right: 20px;
        position: relative;

        .project-admin-bottom-box1-header,
        .project-admin-bottom-box1-footer {
          width: 160px;
          margin: 0 auto;
        }

        .project-admin-bottom-box1-header {
          border-bottom: 1px solid #b5b5b5;
        }

        .project-admin-bottom-box1-center1 {
          text-align: center;
          width: 58px;
          height: 65px;
          margin: 0 auto;
          position: relative;
          top: 48px;
          border: 1px solid red;
          cursor: pointer;

          .icon-a-addto {
            font-size: 32px;
            color: #00a2e6;
          }

          span {
            font-size: 14px;
          }

          b {
            position: absolute;
            right: 10px;
            top: 10px;
            color: #00a2e6;
            background-color: rgba(244, 244, 244, 0.5);
          }
        }
      }
    }

    .project-admin-bottom-dialog {
      width: 600px;
      height: 330px;
      background-color: #fff;
      border: 1px solid red;
      position: relative;
      z-index: 3;
      margin-top: -100px;
    }

    .deleteDialog,
    .addDialog {
      .deleteDialog-center {
        width: 520px;
        margin: 0 auto;

        .deleteDialog-center-header {
          border-bottom: 1px solid #b5b5b5;
        }

        .deleteDialog-center-header-table {
          border-bottom: 1px solid #b5b5b5;

          ul {
            height: 50px;
            display: flex;
            align-items: center;

            .deleteDialog-li1 {
              width: 120px;
              text-align: center;
            }

            .deleteDialog-li2 {
              width: 200px;
              text-align: center;
            }

            li {
              border-right: 1px solid #b5b5b5;
              line-height: 30px;
            }

            li:last-of-type {
              border-right: none;
            }
          }

          .deleteDialog-center-header-table-header {
            width: 520px;
            height: 50px;
            background: #f8f8f8;
          }

          .deleteDialog-center-header-table-bottom {
            height: 50px;

            li {
              border-right: 0;
            }
          }
        }

        .deleteDialog-center-checked-button {
          margin: 0 auto;
          width: 360px;
          height: 24px;

          button {
            width: 160px;
            background-color: #00a2e6;
            font-size: 12px;
          }

          .cancel {
            background-color: #b5b5b5;
          }
        }
      }
    }

    .addDialog {
      .deleteDialog-center-header {
        span {
          color: #00a2e6;
        }
      }
    }
  }

  .project-admin-bottom-employee {
    width: 862px;
    margin: 0 auto;
    border-top: 1px solid #b5b5b5;

    .project-admin-bottom-employee-drop {
      display: flex;
      height: 14px;
      justify-content: space-between;
    }

    .project-admin-bottom-employee-tabel {
      /deep/ .el-table .cell {
        text-overflow: clip;
        overflow: none;
      }
    }

    /deep/ .el-pagination button,
    /deep/ .el-pagination span:not([class*="suffix"]) {
      height: 14px;
    }

    /deep/ .el-pager li {
      height: 14px;
      line-height: 14px;
      /* color: #575757; */
    }
  }
}

/* 验收时的状态 */
.check-status {
  width: 860px;
  height: 56px;
  background: #F4F4F4;
  margin: 0 auto;
  line-height: 56px;
}

/* 周期工时明细 */
.period-hour {
  p {
    margin-left: 40px;
    height: 18px;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #b5b5b5;
  }
}

/* 交付文件清单 */
.taskList-file {
  width: 860px;
  margin: 0 auto;

  .upload-demo {
    width: 860px;
    height: 80px;

    /deep/ .el-upload-dragger {
      width: 860px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

    }

    /deep/ .el-upload__text {
      font-size: 12px;
      color: #575757;
    }

    .upload-center {
      height: 1px;
      display: flex;
      align-items: center;
    }

    img {
      width: 16px;
      height: 16px;
    }

  }

  .task-accessory1 {
    display: flex;
    height: 37px;
    border-bottom: 1px solid #b5b5b5;
    align-items: center;
    position: relative;

    .task-accessory1-left {
      margin-left: 20px;
    }

    .task-accessory1-right {
      width: 580px;
      margin-left: 52px;

      i {
        margin-right: 20px;
      }
    }

    .task-accessory1-center {

    }

    img {
      position: absolute;
      right: 20px;
    }
  }
}


.hoverBlue {
  background-color: #00a2e6;
  color: #fff;
  border-bottom: 1px solid #fff !important;

  .project-admin-bottom-box1-header {
    border-bottom: 1px solid #fff;
  }

  .project-admin-bottom-box1-header span {
    color: #fff;
  }

  .project-admin-bottom-box1-header-img strong, .project-admin-bottom-box1-header-img i {
    color: #fff;
  }

  .project-admin-bottom-box1-footer p {
    color: #fff;
  }
}

.task-file-explain {
  background-color: #fff;

  p {
    padding-bottom: 20px;
  }

  .fileDesp {
    line-height: 20px;
    border-bottom: none;
  }

  /deep/ .el-textarea__inner {
    height: 80px;
  }
}

button {
  width: 120px;
  background-color: #00a2e6;
  font-size: 12px;
  color: #fff;
  display: block;
  margin: 0 auto;
}


.errButton {
  background-color: #fff;
  border: 1px solid #00a2e6;
  color: #00a2e6;
}

.el-table .cell, .el-table th div {
  padding-right: 0;
}

.el-table .cell .el-input__inner {
  width: 100%
}


//评论板块的样式
.task-info-review {
  background-color: #fff;

  .task-info-reviews-all {
    padding: 40px;
    display: flex;
    justify-content: space-between;

    .task-info-reviews-all-item {
      width: 280px;
      text-align: center;

      span {
        font-size: 14px;
        color: #575757;
      }

      p {
        padding-top: 30px;
        padding-bottom: 20px;

        strong {
          font-size: 36px;
        }

        span {
          font-size: 14px;
        }
      }
    }
  }

  .task-info-review-first {
    padding: 20px 40px 20px 40px;

    .task-info-review-first-center {

    }

    .task-info-review-first-success {
      p {
        line-height: 22px;
      }
    }
  }
}

.task-info-review-more {
  .person-header {
    p {
      width: 100%;
      height: 20px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-right: 40px;
    }

  }
}
</style>
